import axios from '@/http/axios'
import {createCancelTokenHandler, makeQuery} from "../axios/utils";

const cancelTokenHandlerObject = {
  getProducts: createCancelTokenHandler('getProducts'),
  getShreddedProducts: createCancelTokenHandler('getShreddedProducts'),
  getAllShreddedProducts: createCancelTokenHandler('getAllShreddedProducts'),
  exportAllShreddedProducts: createCancelTokenHandler('exportAllShreddedProducts'),
  getInStockShreddedProducts: createCancelTokenHandler('getInStockShreddedProducts'),
  getShreddedProductsWithTurnover: createCancelTokenHandler('getShreddedProductsWithTurnover'),
  getProductSaleTurnover: createCancelTokenHandler('getProductSaleTurnover'),
  getProductVariantSaleTurnover: createCancelTokenHandler('getProductVariantSaleTurnover'),
  getProductStoreroomTurnover: createCancelTokenHandler('getProductStoreroomTurnover'),
  getProductVariantStoreroomTurnover: createCancelTokenHandler('getProductVariantStoreroomTurnover'),
  getProductInventory: createCancelTokenHandler('getProductInventory'),
  getProductsTrash: createCancelTokenHandler('getProductsTrash'),
  getAttributes: createCancelTokenHandler('getAttributes')
}

export function getProducts (page, filters = {}, sorts = []) {
  let queries = makeQuery(page, filters, sorts)

  if (queries.length > 0) queries = `?${  queries.join('&')}`

  return axios({
    url: `v1/p&s/products${queries}`,
    method: 'get',
    // setting a cancel token and canceling the previous request of this type
    cancelToken: cancelTokenHandlerObject['getProducts'].handleRequestCancellation().token
  })
}

export function getShreddedProducts (page, filters = {}, sorts = [], stock = false) {
  let queries = makeQuery(page, filters, sorts)

  if (stock) {
    queries.push('stock=true')
  }

  if (queries.length > 0) queries = `?${  queries.join('&')}`

  return axios({
    url: `v1/p&s/shredded-products${queries}`,
    method: 'get',
    // setting a cancel token and canceling the previous request of this type
    cancelToken: cancelTokenHandlerObject['getShreddedProducts'].handleRequestCancellation().token
  })
}

export function getAllShreddedProducts (page, filters = {}, sorts = [], stock = false) {
  let queries = makeQuery(page, filters, sorts)

  if (stock) {
    queries.push('stock=true')
  }

  if (queries.length > 0) queries = `?${  queries.join('&')}`

  return axios({
    url: `v1/p&s/shredded-products/all${queries}`,
    method: 'get',
    // setting a cancel token and canceling the previous request of this type
    cancelToken: cancelTokenHandlerObject['getAllShreddedProducts'].handleRequestCancellation().token
  })
}

export function exportAllShreddedProducts (filters = {}, sorts = [], stock = false, type = 'pdf') {
  let queries = makeQuery(filters, sorts)

  if (stock) {
    queries.push('stock=true')
  }

  if (queries.length > 0) queries = `&${  queries.join('&')}`

  return axios({
    url: `v1/p&s/shredded-products/all?export=${type}${queries}`,
    method: 'get',
    responseType: 'blob',
    // setting a cancel token and canceling the previous request of this type
    cancelToken: cancelTokenHandlerObject['exportAllShreddedProducts'].handleRequestCancellation().token
  })
}

export function getInStockShreddedProducts (warehouse, page, filters = {}, sorts = []) {
  let queries = makeQuery(page, filters, sorts)

  if (queries.length > 0) queries = `?${  queries.join('&')}`

  return axios({
    url: `v1/p&s/shredded-products/${warehouse}/in-stock${queries}`,
    method: 'get',
    // setting a cancel token and canceling the previous request of this type
    cancelToken: cancelTokenHandlerObject['getInStockShreddedProducts'].handleRequestCancellation().token
  })
}

export function getShreddedProductsWithTurnover (page, filters = {}, sorts = []) {
  let queries = makeQuery(page, filters, sorts)

  if (queries.length > 0) queries = `?${  queries.join('&')}`

  return axios({
    url: `v1/p&s/shredded-products/with-turnover${queries}`,
    method: 'get',
    // setting a cancel token and canceling the previous request of this type
    cancelToken: cancelTokenHandlerObject['getShreddedProductsWithTurnover'].handleRequestCancellation().token
  })
}

export function exportProducts ( filters = {}, sorts = [], type = 'pdf') {
  let queries = makeQuery(0, filters, sorts)

  if (queries.length > 0) queries = `&${  queries.join('&')}`

  return axios({
    url: `v1/p&s/products?export=${type}${queries}`,
    method: 'get',
    responseType: 'blob',
  })
}

export function getProduct (id) {

  return axios({
    url: `v1/p&s/products/${id}`,
    method: 'get'
  })
}

export function getProductStatus (id) {

  return axios({
    url: `v1/p&s/products/${id}/status`,
    method: 'get'
  })
}

export function getProductSaleTurnover (id, page = 1, filters = {}, sorts = []) {
  let queries = makeQuery(page, filters, sorts)

  if (queries.length > 0) queries = `?${  queries.join('&')}`

  return axios({
    url: `v1/p&s/products/${id}/turnover${queries}`,
    method: 'get',
    // setting a cancel token and canceling the previous request of this type
    cancelToken: cancelTokenHandlerObject['getProductSaleTurnover'].handleRequestCancellation().token
  })
}

export function exportProductSaleTurnover ( id, filters = {}, sorts = [], type = 'pdf') {
  let queries = makeQuery(0, filters, sorts)

  if (queries.length > 0) queries = `&${  queries.join('&')}`

  return axios({
    url: `v1/p&s/products/${id}/turnover?export=${type}${queries}`,
    method: 'get',
    responseType: 'blob',
  })
}

export function getProductVariantSaleTurnover (productId, variantId, page = 1, filters = {}, sorts = []) {
  let queries = makeQuery(page, filters, sorts)

  if (queries.length > 0) queries = `?${  queries.join('&')}`

  return axios({
    url: `v1/p&s/products/${productId}/variants/${variantId}/sale-turnover${queries}`,
    method: 'get',
    // setting a cancel token and canceling the previous request of this type
    cancelToken: cancelTokenHandlerObject['getProductVariantSaleTurnover'].handleRequestCancellation().token
  })
}

export function exportProductVariantSaleTurnover ( productId, variantId, filters = {}, sorts = [], type = 'pdf') {
  let queries = makeQuery(0, filters, sorts)

  if (queries.length > 0) queries = `&${  queries.join('&')}`

  return axios({
    url: `v1/p&s/products/${productId}/variants/${variantId}/sale-turnover?export=${type}${queries}`,
    method: 'get',
    responseType: 'blob',
  })
}

export function getProductStoreroomTurnover (id, page = 1, filters = {}, sorts = []) {
  let queries = makeQuery(page, filters, sorts)

  if (queries.length > 0) queries = `?${  queries.join('&')}`

  return axios({
    url: `v1/p&s/products/${id}/storeroom-turnover${queries}`,
    method: 'get',
    // setting a cancel token and canceling the previous request of this type
    cancelToken: cancelTokenHandlerObject['getProductStoreroomTurnover'].handleRequestCancellation().token
  })
}

export function exportProductStoreroomTurnover ( id, filters = {}, sorts = [], type = 'pdf') {
  let queries = makeQuery(0, filters, sorts)

  if (queries.length > 0) queries = `&${  queries.join('&')}`

  return axios({
    url: `v1/p&s/products/${id}/storeroom-turnover?export=${type}${queries}`,
    method: 'get',
    responseType: 'blob',
  })
}

export function getProductVariantStoreroomTurnover (productId, variantId, page = 1, filters = {}, sorts = []) {
  let queries = makeQuery(page, filters, sorts)

  if (queries.length > 0) queries = `?${  queries.join('&')}`

  return axios({
    url: `v1/p&s/products/${productId}/variants/${variantId}/storeroom-turnover${queries}`,
    method: 'get',
    // setting a cancel token and canceling the previous request of this type
    cancelToken: cancelTokenHandlerObject['getProductVariantStoreroomTurnover'].handleRequestCancellation().token
  })
}

export function exportProductVariantStoreroomTurnover ( productId, variantId, filters = {}, sorts = [], type = 'pdf') {
  let queries = makeQuery(0, filters, sorts)

  if (queries.length > 0) queries = `&${  queries.join('&')}`

  return axios({
    url: `v1/p&s/products/${productId}/variants/${variantId}/storeroom-turnover?export=${type}${queries}`,
    method: 'get',
    responseType: 'blob',
  })
}

export function getProductInventory (id, page = 1, filters = {}, sorts = []) {
  let queries = makeQuery(page, filters, sorts)

  if (queries.length > 0) queries = `?${  queries.join('&')}`

  return axios({
    url: `v1/warehousing/inventory/${id}${queries}`,
    method: 'get',
    // setting a cancel token and canceling the previous request of this type
    cancelToken: cancelTokenHandlerObject['getProductInventory'].handleRequestCancellation().token
  })
}

export function exportProductInventory ( id, filters = {}, sorts = [], type = 'pdf') {
  let queries = makeQuery(0, filters, sorts)

  if (queries.length > 0) queries = `&${  queries.join('&')}`

  return axios({
    url: `v1/warehousing/inventory/${id}?export=${type}${queries}`,
    method: 'get',
    responseType: 'blob',
  })
}

export function getProductWithCode (code) {

  return axios({
    url: `https://sport.useral.com/api/v1/admin/products?filters[code][text]=${code}&filters[code][type]=2&with_details=true`,
    method: 'get'
  })
}

export function getProductActivitiesLog (id, page, filters = {}, sorts = []) {
  let queries = makeQuery(page, filters, sorts)

  if (queries.length > 0) queries = `?${  queries.join('&')}`
  return axios({
    url: `v1/p&s/products/${id}/activity-logs${queries}`,
    method: 'get'
  })
}

export function insertProduct (payload) {

  return axios({
    url: 'v1/p&s/products',
    data: payload,
    method: 'post'
  })
}

export function getProductsTrash (page, filters = {}, sorts = []) {
  let queries = makeQuery(page, filters, sorts)

  if (queries.length > 0) queries = `&${  queries.join('&')}`

  return axios({
    url: `v1/p&s/products?trashed=true${queries}`,
    method: 'get',
    // setting a cancel token and canceling the previous request of this type
    cancelToken: cancelTokenHandlerObject['getProductsTrash'].handleRequestCancellation().token
  })
}

export function editProduct (id, payload) {

  return axios.patch(`v1/p&s/products/${id}`, payload)
}

export function setProductPrice (variantId, payload) {

  return axios.patch(`v1/sales/products-pricing/${variantId}`, payload)
}

export function deleteProduct (id, force = false) {

  return axios({
    url: `v1/p&s/products/${id}${force ? '/force' : ''}`,
    method: 'delete'
  })
}

export function restoreProduct (id) {

  return axios({
    url: `v1/p&s/products/${id}/restore`,
    method: 'put'
  })
}

/* crud functions for attributes  */

export function getAttributes (page, all = false) {
  let queries = makeQuery(page)

  if (all) {
    queries.push('all=true')
  }

  if (queries.length > 0) queries = `?${queries.join('&')}`

  return axios({
    url: `v1/p&s/attributes${queries ? queries : ''}`,
    method: 'get',
    // setting a cancel token and canceling the previous request of this type
    cancelToken: cancelTokenHandlerObject['getAttributes'].handleRequestCancellation().token
  })
}

export function deleteAttributeValue (id) {

  return axios({
    url: `v1/p&s/attribute-values/${id}`,
    method: 'delete'
  })
}
/* crud functions for attributes */

