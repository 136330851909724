import axios from '@/http/axios'

export function getPermissions () {
  return axios({
    url: 'v1/settings/permissions',
    method: 'get'
  })
}

export function getRoles () {
  return axios({
    url: 'v1/settings/roles',
    method: 'get'
  })
}

export function getRolePermissions (id) {
  return axios({
    url: `v1/settings/roles/${id}/permissions`,
    method: 'get'
  })
}

export function insertUserToRole (role, payload) {
  return axios({
    url: `v1/settings/roles/${role}/users`,
    data: payload,
    method: 'post'
  })
}

export function deleteUserFromRole (role, userId) {
  return axios({
    url: `v1/settings/roles/${role}/users/${userId}`,
    method: 'delete'
  })
}

export function insertRole (role) {
  return axios({
    url: 'v1/settings/roles',
    data: role,
    method: 'post'
  })
}

export function editRole (role) {
  const id = role.id
  delete role.id
  return axios.patch(`v1/settings/roles/${id}`, role)
}

export function deleteRole (id) {
  return axios({
    url: `v1/settings/roles/${id}`,
    method: 'delete'
  })
}
