import axios from '@/http/axios'
import {createCancelTokenHandler, makeQuery} from "../axios/utils";

const cancelTokenHandlerObject = {
  getBanks: createCancelTokenHandler('getBanks'),
  getBanksTrash: createCancelTokenHandler('getBanksTrash'),
  getBankTransactions: createCancelTokenHandler('getBankTransactions'),
}

export function getBanks (page = 1, filters = {}, sorts = []) {
  let queries = makeQuery(page, filters, sorts)

  if (queries.length > 0) queries = `?${  queries.join('&')}`

  return axios({
    url: `v1/treasury/banks${queries}`,
    method: 'get',
    // setting a cancel token and canceling the previous request of this type
    cancelToken: cancelTokenHandlerObject['getBanks'].handleRequestCancellation().token
  })
}

export function exportBanks ( filters = {}, sorts = [], type = 'pdf') {
  let queries = makeQuery(0, filters, sorts)

  if (queries.length > 0) queries = `&${  queries.join('&')}`

  return axios({
    url: `v1/treasury/banks?export=${type}${queries}`,
    method: 'get',
    responseType: 'blob',
  })
}

export function getBanksTrash (page = 1, filters = {}, sorts = []) {
  let queries = makeQuery(page, filters, sorts)

  if (queries.length > 0) queries = `&${  queries.join('&')}`
  return axios({
    url: `v1/treasury/banks?trashed=true${queries}`,
    method: 'get',
    // setting a cancel token and canceling the previous request of this type
    cancelToken: cancelTokenHandlerObject['getBanksTrash'].handleRequestCancellation().token
  })
}

export function getBank (id) {
  return axios({
    url: `v1/treasury/banks/${id}`,
    method: 'get'
  })
}

export function getBankTransactions (id, page = 1, filters = {}, sorts = []) {
  let queries = makeQuery(page, filters, sorts)

  if (queries.length > 0) queries = `?${  queries.join('&')}`

  return axios({
    url: `v1/treasury/banks/${id}/transactions${queries}`,
    method: 'get',
    // setting a cancel token and canceling the previous request of this type
    cancelToken: cancelTokenHandlerObject['getBankTransactions'].handleRequestCancellation().token
  })
}

export function getBankActivitiesLog (id, page, filters = {}, sorts = []) {
  let queries = makeQuery(page, filters, sorts)

  if (queries.length > 0) queries = `?${  queries.join('&')}`
  return axios({
    url: `v1/treasury/banks/${id}/activity-logs${queries}`,
    method: 'get'
  })
}

export function insertBank (payment) {
  return axios({
    url: 'v1/treasury/banks',
    data: payment,
    method: 'post'
  })
}

export function editBank (payment) {
  return axios.patch(`v1/treasury/banks/${payment.id}`, payment)
}

export function restoreBank (id) {
  return axios({
    url: `v1/treasury/banks/${id}/restore`,
    method: 'put'
  })
}

export function deleteBank (id, force = false) {
  return axios({
    url: `v1/treasury/banks/${id}${force ? '/force' : ''}`,
    method: 'delete'
  })
}
