import axios from '@/http/axios'

// avatars
export function uploadAssets (data, type = 'avatars') {
  return axios({
    url: `assets/${type}/upload`,
    data,
    method: 'post'
  })
}

export function uploadAttachment (data, type = 'avatars') {
  return axios({
    url: `assets/attachments/${type}`,
    data,
    method: 'post'
  })
}
