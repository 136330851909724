import axios from '@/http/axios'
import store from '../../../store/store'
import {createCancelTokenHandler, makeQuery} from "../../axios/utils";

const cancelTokenHandlerObject = {
  getUsers: createCancelTokenHandler('getUsers'),
  getUsersTrash: createCancelTokenHandler('getUsersTrash'),
  getUserTransaction: createCancelTokenHandler('getUserTransaction'),
  getUserGroups: createCancelTokenHandler('getUserGroups')
}

/* Users */

export function getUsers (page, filters = {}, sorts = []) {
  let queries = makeQuery(page, filters, sorts)

  if (queries.length > 0) queries = `?${  queries.join('&')}`

  return axios({
    url: `v1/users${queries}`,
    method: 'get',
    // setting a cancel token and canceling the previous request of this type
    cancelToken: cancelTokenHandlerObject['getUsers'].handleRequestCancellation().token
  })
}

export function exportUsers ( filters = {}, sorts = [], type = 'pdf') {
  let queries = makeQuery(0, filters, sorts)

  if (queries.length > 0) queries = `&${  queries.join('&')}`

  return axios({
    url: `v1/users?export=${type}${queries}`,
    method: 'get',
    responseType: 'blob',
  })
}

export function getUsersTrash (page, filters = {}, sorts = []) {
  let queries = makeQuery(page, filters, sorts)

  if (queries.length > 0) queries = `&${  queries.join('&')}`

  return axios({
    url: `v1/users?trashed=true${queries}`,
    method: 'get',
    // setting a cancel token and canceling the previous request of this type
    cancelToken: cancelTokenHandlerObject['getUsersTrash'].handleRequestCancellation().token
  })
}

export function getUser (id) {
  return axios({
    url: `v1/users/${id}`,
    method: 'get'
  })
}

export function getUserBrowserSettings (id) {
  return axios({
    url: `v1/users/${id}/browser-settings`,
    method: 'get'
  })
}

export function insertUserBrowserSettings (setting) {
  const userId = setting.id
  delete setting.id

  return axios({
    url: `v1/users/${userId}/browser-settings`,
    data: setting,
    method: 'post'
  })
}

export function sendMessageToUser (id, payment) {
  return axios({
    url: `v1/users/${id}/send-message`,
    data: payment,
    method: 'post'
  })
}

export function sendMessageToUsers (filters) {
  let queries = makeQuery(0, filters, [])

  if (queries.length > 0) queries = `?${  queries.join('&')}`
  return axios({
    url: `v1/send-message-to-users${queries}`,
    method: 'post'
  })
}

export function editUserBrowserSettings (setting) {
  const userId = setting.id
  delete setting.id

  return axios.patch(`v1/users/${userId}/browser-settings`, setting)
}

export function getUserActivitiesLog (id, page, filters = {}, sorts = []) {
  let queries = makeQuery(page, filters, sorts)

  if (queries.length > 0) queries = `?${  queries.join('&')}`
  return axios({
    url: `v1/users/${id}/activity-logs${queries}`,
    method: 'get'
  })
}

export function insertUser (payment) {
  return axios({
    url: 'v1/users',
    data: payment,
    method: 'post'
  })
}

export function editUser (id, payment) {
  return axios.patch(`v1/users/${id}`, payment)
}

export function deleteUser (id, force = false) {
  return axios({
    url: `v1/users/${id}${force ? '/force' : ''}`,
    method: 'delete'
  })
}

export function restoreUser (id) {
  return axios({
    url: `v1/users/${id}/restore`,
    method: 'put'
  })
}

export function receiveFromUser (payment) {
  return axios({
    url: `v1/admin/users/${payment.id}/receive`,
    data: payment,
    method: 'post'
  })
}

export function paymentToUser (payment) {
  return axios({
    url: `v1/admin/users/${payment.id}/payment`,
    data: payment,
    method: 'post'
  })
}

export function getUserTransaction (userId, page, filters = {}, sorts = []) {
  let queries = makeQuery(page, filters, sorts)

  if (queries.length > 0) queries = `?${  queries.join('&')}`

  return axios({
    url: `v1/users/${userId}/transactions${queries}`,
    method: 'get',
    // setting a cancel token and canceling the previous request of this type
    cancelToken: cancelTokenHandlerObject['getUserTransaction'].handleRequestCancellation().token
  })
}

/* User Addresses */

export function getUserAddresses (userId) {
  return axios({
    url: `v1/users/${userId}/addresses`,
    method: 'get'
  })
}

export function insertUserAddress (userId, address) {
  return axios({
    url: `v1/users/${userId}/addresses`,
    data: address,
    method: 'post'
  })
}

export function editUserAddress (userId, addressId, address) {
  return axios.patch(`v1/users/${userId}/addresses/${addressId}`, address)
}

export function deleteUserAddress (userId, addressId) {
  return axios({
    url: `v1/users/${userId}/addresses/${addressId}`,
    method: 'delete'
  })
}

/* User Groups */

export function getUserGroups (page = 1) {
  return axios({
    url: `v1/user-groups?page=${page}`,
    method: 'get',
    // setting a cancel token and canceling the previous request of this type
    cancelToken: cancelTokenHandlerObject['getUserGroups'].handleRequestCancellation().token
  })
}

export function getUserGroup (id) {
  return axios({
    url: `v1/user-groups/${id}`,
    method: 'get'
  })
}

export function insertUserGroup (payment) {
  return axios({
    url: 'v1/user-groups',
    data: payment,
    method: 'post'
  })
}

export function editUserGroup (payment) {
  return axios.patch(`v1/user-groups/${payment.id}`, payment)
}

export function deleteUserGroup (id) {
  return axios({
    url: `v1/user-groups/${id}`,
    method: 'delete'
  })
}
