import axios from '@/http/axios'
import {createCancelTokenHandler, makeQuery} from "../axios/utils";

const cancelTokenHandlerObject = {
  getAttributes: createCancelTokenHandler('getAttributes'),
  getAttributeProducts: createCancelTokenHandler('getAttributeProducts')
}

export function getAttributes (page, filters = {}) {
  let queries = makeQuery(page, filters)

  if (queries.length > 0) queries = `?${  queries.join('&')}`

  return axios({
    url: `v1/p&s/attributes${queries}`,
    method: 'get',
    // setting a cancel token and canceling the previous request of this type
    cancelToken: cancelTokenHandlerObject['getAttributes'].handleRequestCancellation().token
  })
}

export function getAllAttributes () {

  return axios({
    url: 'v1/p&s/attributes?type=all',
    method: 'get'
  })
}

export function getAvailableAttributes (filters = {}, sorts = []) {
  let queries = makeQuery(1, filters, sorts)

  if (queries.length > 0) queries = `?${  queries.join('&')}`

  return axios({
    url: `v1/p&s/available-attributes${queries}`,
    method: 'get'
  })
}

export function getAttribute (id) {
  return axios({
    url: `v1/p&s/attributes/${id}`,
    method: 'get'
  })
}

export function getAttributeProducts (id, page, filters = {}, sorts=[]) {
  let queries = makeQuery(page, filters, sorts)

  if (queries.length > 0) queries = `?${  queries.join('&')}`
  return axios({
    url: `v1/p&s/attributes/${id}/products${queries}`,
    method: 'get',
    // setting a cancel token and canceling the previous request of this type
    cancelToken: cancelTokenHandlerObject['getAttributeProducts'].handleRequestCancellation().token
  })
}

export function insertAttribute (attribute) {
  return axios({
    url: 'v1/p&s/attributes',
    data: attribute,
    method: 'post'
  })
}

export function setAttributePriorities (attribute) {
  return axios({
    url: 'v1/p&s/attributes-priorities',
    data: attribute,
    method: 'post'
  })
}

export function updateAttribute (payload) {

  return axios.patch(`v1/p&s/attributes/${payload.id}`, payload)
}

export function insertAttributeValue (attributeId, data) {

  return axios({
    url: `v1/p&s/attributes/${attributeId}/values`,
    data,
    method: 'post'
  })
}

export function updateAttributeValue (valueId, data) {

  return axios.patch(`v1/p&s/attribute-values/${valueId}`, data)
}

export function deleteAttributeValue (valueId) {

  return axios({
    url: `v1/p&s/attribute-values/${valueId}`,
    method: 'delete'
  })
}

export function deleteAttribute (id) {

  return axios({
    url: `v1/p&s/attributes/${id}`,
    method: 'delete'
  })
}
