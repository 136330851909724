import axios from '@/http/axios'

export function getProvinces (id) {

  return axios({
    url: `v1/countries/${id}/provinces`,
    method: 'get'
  })
}

export function getAllProvinces () {

  return axios({
    url: `v1/provinces`,
    method: 'get'
  })
}
export function getCountries () {

  return axios({
    url: 'v1/countries',
    method: 'get'
  })
}
