<template>
  <div class="u-checkbox">
    <label class="no-select text-body-1">
      <input class="form-check-input"
             type="checkbox"
             :disabled="disabled"
             v-model="changeValue">
      <span v-if="label" class="u-checkbox-label">
        {{ label }}
      </span>
    </label>
  </div>
</template>

<script>
export default {
  name: "UCheckbox",
  model: {
    prop: 'checked',
    event: 'checked'
  },
  props: {
    label: {
      type: String,
      default: ''
    },
    checked: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    changeValue: {
      get() {
        return this.checked
      },
      set(val) {
        this.$emit('checked', val)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.u-checkbox {

  label {
    display: flex;
    align-items: center;

    .form-check-input {
      width: 17px;
      height: 17px;
    }

    .u-checkbox-label {
      margin-left: 10px;
    }
  }
}
</style>
